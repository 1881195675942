export const menuItems = [
  {
    title: "Kanapki",
    category: "sandwich",
    items: [
      {
        title: "PULLED PORK ONION",
        description:
          "bułka panini, dymiony wieprz, krążki cebulowe, sałata, pikantna salsa pomidorowa",
        price: "28 PLN",
      },
      {
        title: "PULLED PORK CHEESE",
        description:
          "bułka panini, wolno dymiony wieprz, ser, ogórek konserwowy, jalapeno, blue cheese (lekko pikantny)",
        price: "28 PLN",
      },
      {
        title: "HOT DOG CLASSIC",
        description:
          "bułka panini, grillowana kiełbasa z cebulką, ketchup, musztarda",
        price: "21 PLN",
      },
      {
        title: "HOT DOG TEXAS",
        description:
          "bułka panini, grillowana kiełbasa z cebulką, ogórek konserwowy, pomidor, prażona cebulka, ser ketchup, musztarda",
        price: "24 PLN",
      },
      {
        title: "HOT DOG HOT",
        description:
          "bułka panini, grillowana kiełbasa z cebulką, jalapeno, kukurydza, ser, pikantna salsa pomidorowa",
        price: "24 PLN",
      },
    ],
  },
  {
    title: "Burgery",
    category: "burgers",
    items: [
      {
        title: "POJEDZONY",
        description:
          "bułka własnego wypieku, wołowina, podwójny bekon, podwójny cheddar, ogórek konserwowy, mix sałat, sos.",
        price: "42 PLN",
        class: "burgers",
      },

      {
        title: "JACK",
        description:
          "bułka własnego wypieku, wołowina, bekon, ser, ogórek konserwowy, czerwona cebula, mix sałat, sos.",
        price: "39 PLN",
        class: "burgers",
      },
      {
        title: "LEŚNY SKRZAT",
        description:
          "bułka naszego wypieku, wołowina, pieczarki, blue cheese, pomidor, sałata, czerwona cebula, mix sałat, sos.",
        price: "42 PLN",
        class: "burgers",
      },
      {
        title: "HADES",
        warning:"Ostry lub mega ostry +3zł",
        description:
          "bułka naszego wypieku, wołowina, bekon, grilowane warzywa, mix sałat, jalapeno, sos habanero (OSTRY LUB MEGA OSTRY + 3zł).",
        price: "40 PLN",
        class: "burgers",
      },
      {
        title: "JAJCARZ",
        warning: "Nowość",
        description:
          "bułka naszego wypieku, dymiony wieprz, bekon, sadzone jajo, pieczarki, sałata, sos.",
        price: "41 PLN",
        class: "burgers",
      },
      {
        title: "VEGE BURGER",
        description:
          "bułka naszego wypieku, wege kotlet własnej receptury, ser cheddar, mix sałat, grillowane warzywa, pomidor, sos.",
        price: "38 PLN",
        class: "burgers",
      },
      {
        title: "VEGE REGE",
        description: "bułka naszego wypieku, camembert w migdałach, pomidor suszony, mix sałat, sos żurawinowy",
        price: "35 PLN",
        class: "burgers",
      },
      {
        title: "SER-WOWANY",
        description:
          "bułka naszego wypieku, sos barbeque, wołowina, bekon, papryka pepperoni, czerwona cebula, sałata, pomidor, mix sałat, sos serowy",
        price: "48 PLN",
        class: "burgers",
      },
    ]
  },
  {
    title: "Steki and BBQ",
    category: "steak",
    
    items: [
      {
        title: "Wołowy z antrykotu",
        additionalInfo: "Dostępne stopnie wysmażenia - medium, well done",
        description:
          "ok 250g surowego mięsa, frytki stekowe, warzywa grillowane, sos BBQ, masło czosnkowe.",
        price: "65 PLN",
      },
      {
        title: "Stek wołowy z antrykotu double meat",
        additionalInfo: "Dostępne stopnie wysmażenia - medium, well done",
        description:
          "ok 500g surowego mięsa, frytki stekowe, warzywa grillowane, sos BBQ, masło czosnkowe.",
        price: "83 PLN",
      },
      {
        title: "Golonka wieprzowa",
        description: "ziemniaki opiekane, kapusta zasmażana, chrzan, musztarda",
        price: "49 PLN",
      },
      {
        title: "Filet drobiowy",
        description:
          "grillowany filet z kurczaka ok 200g, frytki, surówka, pikantna salsa pomidorowa.",
        price: "37 PLN",
      },
      {
        title: "Wolnopieczone żebro BBQ ",
        description:
          "ziemniaki opiekane z czosnkiem, warzywa grillowane, sos BBQ.",
        price: "52 PLN",
      },
      {
        title: "Zestaw BBQ dla 2 osób",
        description:
          "mix mięs z grilla, ziemniaki opiekane, surówka, pikle, kapusta zasmażana, fasolka, kapusta zasmażana, pieczywko, sosy.",
        price: "84 PLN",
      },
      {
        title: "Zapiekany pulled pork z serem",
        description: "grillowane warzywa, frytki, pikantna salsa pomidorowa",
        price: "42 PLN",
      },
    ]
  },
  {
    title: "Pierogi",
    category: "pierogi",
    items: [
      {
        title: "Ruskie",
        description: "ok 400g.",
        price: "23 PLN",
      },
      {
        title: "Mięsne",
        description: "ok 400g.",
        price: "26 PLN",
      },
    ]
  },
  {
    title: "Food kids",
    category: "kids",
    items: [
      {
        title: "Burger Liliput",
        description:
          "bułka naszego wypieku, grillowany filet z kurczaka, ser, ogórek, ketchup",
        price: "22 PLN",
      },
      {
        title: "Nuggetsy z frytkami",
        description: "sos czosnkowy",
        price: "23 PLN",
      },
      {
        title: "Gofr",
        description: "z dżemem owocowym i bitą śmietaną",
        price: "16 PLN",
      },
      {
        title: "Frytki",
        description: "",
        price: "11 PLN",
      },
    ]
  },
  {
    title: "Napoje",
    category: "drinks",
    items: [
      {
        title: "LEMONIADA wg. własnej receptury.",
        description: "0,3l",
        price: "12 PLN",
      },
      {
        title: "Napoje",
        description: "Pepsi, Mirinda, 7up, Lipton 0,2l / 0,5l",
        price: "8 PLN / 12 PLN",
      },
      {
        title: "Soki",
        description: "Pomarańcza, jabłko, czarna porzeczka 0,2l / dzbanek 1l",
        price: "8 PLN / 16 PLN",
      },
      {
        title: "Kawa czarna",
        description: " ",
        price: "9 PLN",
      },
      {
        title: "Kawa biała",
        description: " ",
        price: "10 PLN",
      },
      {
        title: "Kawa latte",
        description: " ",
        price: "12 PLN",
      },
      {
        title: "Herbata czarna, zielona, owocowa",
        description: " ",
        price: "9 PLN",
      },
      {
        title: "Woda 0,5l ",
        description: " ",
        price: "6 PLN",
      },
    ]
  },
  {
    title: "Piwo",
    category: "beer",
    items: [
      {
        title: "Bezalkoholowe 0.5l",
        description: "Lech Free Limonka z miętą, Lecz Free Smoczy owoc i winogrono, Lech Free Active Liczi i cytryna, Książęce Złote Pszeniczne, Kozel",
        price: "13 PLN",
      },
      {
        title: "Piwo butelkowe 0.5l",
        description: "Pilsner Urquell, Książęce Złote Pszeniczne, Książęce IPA, Książęce Cherry Ale, Lech Premium, Kozel Cerny",
        price: "13 PLN",
      },
      {
        title: "Piwo beczkowe",
        description: "Kozel leżak 0,3l / 0,5l",
        price: "9 PLN / 12 PLN",
      },
      {
        title: "Piwo kraftowe",
        description: "0,5l",
        price: "16 PLN",
      },
    ]
  },
 {
   title: "Zupy",
   category: "soup",
   items: [
    {
      title: "ŻUREK NA WŁASNYM ZAKWASIE",
      description: "kiełbasa, jajko, pieczywo",
      price: "22 PLN",
    },
    {
      title: "ZUPA POMIDOROWA",
      description: "pulpety drobiowe, ryż, oliwa lubczykowa",
      price: "22 PLN",
    },
   ]
 },
]